import { Box, BoxProps, ButtonGroup, Popover } from '@mui/material';
import { MouseEvent, useCallback, useMemo, useState } from 'react';

import { LANGS, Lang } from '@sbiz/common';

import { styledProps } from '../../common/styles';
import { useLang } from '../../hooks/useLang';
import { Button, ButtonProps } from '../atoms/Button';

export function LangSelector(props: BoxProps) {
  const [anchor, setAnchor] = useState<MouseEvent['currentTarget']>();

  const [lang] = useLang();

  const otherLangs = useMemo(() => LANGS.filter((otherLang) => otherLang !== lang).sort(), [lang]);

  const hideOthers = useCallback(() => {
    setAnchor(undefined);
  }, []);

  const showOthers = useCallback(({ currentTarget }: MouseEvent) => {
    setAnchor(currentTarget);
  }, []);

  return (
    <>
      <Box {...props}>
        <LangButton lang={lang} onClick={showOthers} />
      </Box>

      <Popover
        anchorEl={anchor}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        onClose={hideOthers}
        open={Boolean(anchor)}
        sx={{ mt: 0.5 }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <ButtonGroup onClick={hideOthers} orientation="vertical">
          {otherLangs.map((otherLang) => (
            <LangButton lang={otherLang} key={otherLang} />
          ))}
        </ButtonGroup>
      </Popover>
    </>
  );
}

function LangButton({ lang, ...props }: { lang: Lang } & ButtonProps) {
  const [, setLang] = useLang();

  const buttonProps = useMemo(
    () =>
      styledProps({ backgroundColor: 'white', p: 1.5 }, {
        onClick: () => {
          setLang(lang);
        },
        variant: 'text',
        ...props,
      } as const),
    [lang, props, setLang],
  );

  return <Button {...buttonProps}>{lang.toUpperCase()}</Button>;
}
