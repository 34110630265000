'use client';

import { Box, useMediaQuery } from '@mui/material';
import { ReactNode } from 'react';

import { SxArray } from '../../common/styles';
import { Image, Brand, FlexBox } from '../atoms';
import { LangSelector } from '../molecules/LangSelector';

const AUTHENTICATION_PAGE_STYLES = [
  {
    backgroundColor: 'white',
    justifyContent: 'center',
    minHeight: '100vh',
    '& > div': { maxWidth: ({ breakpoints }) => breakpoints.values.xl },
  },
  { minHeight: '100svh' },
] as const satisfies SxArray;

export function PublicPage({ children }: { children?: ReactNode }) {
  const isSmallScreen = useMediaQuery(({ breakpoints }) => breakpoints.down('md'));

  return (
    <FlexBox component="main" sx={AUTHENTICATION_PAGE_STYLES}>
      <FlexBox
        column
        sx={[
          { backgroundColor: ({ palette: { background } }) => background.default, flex: '0 0 50%' },
          isSmallScreen && { flexGrow: 1 },
        ]}
      >
        <FlexBox sx={[{ alignItems: 'center', pt: 5, px: 5 }, isSmallScreen && { pt: 3, px: 3 }]}>
          <Brand breakpoint="lg" />
          <LangSelector sx={{ ml: 'auto' }} />
        </FlexBox>

        {children && (
          <FlexBox column sx={{ my: 'auto', py: 2 }}>
            <Box sx={{ mx: 'auto', width: '80%' }}>{children}</Box>
          </FlexBox>
        )}
      </FlexBox>

      <Box sx={[{ flexGrow: 1, position: 'relative' }, isSmallScreen && { display: 'none' }]}>
        <Image src="/images/bg-auth.jpg" alt="" width={0} height={0} layout="fill" objectFit="cover" priority />
      </Box>
    </FlexBox>
  );
}
