import { Refresh } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FlexBox } from '../atoms';
import { PublicPage } from '../templates/PublicPage';

export function Error({ reset }: { reset: () => void }) {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({ reset: t('buttons.refresh'), subtitle: t('pages.error.subtitle'), title: t('pages.error.title') }),
    [t],
  );

  return (
    <PublicPage>
      <FlexBox column sx={{ alignItems: 'center', gap: 2 }}>
        <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
          {labels.title}
        </Typography>

        <Typography component="h2" variant="h6" sx={{ fontSize: 'large' }}>
          {labels.subtitle}
        </Typography>

        <Button onClick={reset} startIcon={<Refresh />} sx={{ mt: 2 }}>
          {labels.reset}
        </Button>
      </FlexBox>
    </PublicPage>
  );
}
